import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCookie from 'react-use-cookie';
import useLocalStorageState from 'use-local-storage-state'
import { fetchUserData, manualUpdatesSelector, setManualUpdatesContext, setMeta, setSportsEnabledContext, sportEnabledSelector } from '../Redux/appSlice';
import { withAppContext } from "./AppContextProvider";

const UserSettingsContext = React.createContext({});
const useUserSettingsContext = () => useContext(UserSettingsContext);

const UserSettingsContextProvider = ({ appType, children }) => {
  const dispatch = useDispatch();
  const [appTheme, setAppTheme, {removeItem}] = useLocalStorageState(ENV_COOKIE_THEME);
  const [areCookiesEnabled, setCookiesEnabled] = useState(false);
  const manualUpdatesSelected = useSelector(manualUpdatesSelector);
  const sportsEnabledSelected = useSelector(sportEnabledSelector);

  useEffect(() => {
    manualUpdatesSelected !== null && setAutoAddNewPosts(manualUpdatesSelected);
  }, [manualUpdatesSelected]);
  useEffect(() => {
    sportsEnabledSelected !== null && setSportEnabled(sportsEnabledSelected);
  }, [sportsEnabledSelected]);

  const setDarkMode = (darkMode) => {
    if (systemDarkMode) {
      if (darkMode === true) removeItem();
      else setAppTheme('light');
    } else {
      if (darkMode === true) setAppTheme('dark');
      else removeItem();
    }
  };

  /// logged in user  ---------------------------------------------------------------
  const [nToken] = useCookie(ENV_COOKIE_N_TOKEN, '', { SameSite: 'Lax', Secure: true });

  useEffect(() => {
    nToken !== '' && dispatch(fetchUserData());
  }, [nToken]);

  /// sports enabled  ---------------------------------------------------------------
  const [sportEnabledCookie, setSportEnabledCookie] = useLocalStorageState(ENV_COOKIE_SPORT_ENABLED,{defaultValue: true});
  const [autoAddNewPostsCookie, setAutoAddNewPostsCookie] = useLocalStorageState(ENV_COOKIE_AUTO_ADD_NEW_POSTS, {defaultValue: false});

  const [systemDarkMode, setSystemDarkMode] = useState(() => {
    try {
      /// SSR will fail on window
      const matchedMedia = window.matchMedia('(prefers-color-scheme: dark)');
      return !!matchedMedia.matches;
    } catch (e) {
      return false;
    }
  });

  /// setter
  const setSportEnabled = (value) => {
    if (value !== sportsEnabledSelected) {
      setSportEnabledCookie(value);
      dispatch(setSportsEnabledContext(value));

      /// if nToken is not empty, user is logged in
      if (nToken !== '') {
        dispatch(setMeta({ nToken: nToken, key: 'sport_enabled', value: value }));
      }
    }
  };

  const setAutoAddNewPosts = (value) => {
    if (value !== manualUpdatesSelected) {
      setAutoAddNewPostsCookie(value);
      dispatch(setManualUpdatesContext(value));

      /// if nToken is not empty, user is logged in
      if (nToken !== '') {
        dispatch(setMeta({ nToken: nToken, key: 'mnt_manual', value: value }));
      }
    }
  };

  /// -------------------------------------------------------------------------------
  const [lastArticleSeen, setLastArticleSeen] = useLocalStorageState('lastarticleseen', {defaultValue: Date.now()});

  useEffect(() => {
    const appContainer = (appType === 'feed')
      ? document.body
      : document.getElementsByClassName("mnt-Minuta")[0];
    if (appTheme === 'dark' || typeof appTheme === 'undefined' && systemDarkMode) {
      appContainer.classList.add('mnt-darkTheme');
      if (typeof window.swDarkMode === 'function') { window.swDarkMode(); }
    } else {
      appContainer.classList.remove('mnt-darkTheme');
      if (typeof window.swLightMode === 'function') { window.swLightMode(); }
    }
  }, [appTheme, systemDarkMode]);

  useEffect(() => {
    dispatch(fetchUserData());
    if (window.navigator) {
      setCookiesEnabled(window.navigator.cookieEnabled);
    }
    const N3ThemeListener = (event) => {
      if (event.detail?.theme === 'dark' || event.detail?.theme === 'light') {
        setDarkMode(event.detail.theme === 'dark');
      }
    }

    // Support for older browser eg. IOS 12 era + addListener vs addEventListener.
    if (window.matchMedia) {
      const matchedMedia = window.matchMedia('(prefers-color-scheme: dark)');
      setSystemDarkMode(!!matchedMedia.matches);

      const systemColorSchemeListener = (event) => {
        setSystemDarkMode(!!event.matches);
      };

      window.addEventListener('n3:theme-change', N3ThemeListener);
      if (typeof matchedMedia.addEventListener === 'function') {
        matchedMedia.addEventListener('change', systemColorSchemeListener);
      }
      else if (typeof matchedMedia.addListener === 'function') {
        matchedMedia.addListener(systemColorSchemeListener);
      }
      return () => {
        const matchedMedia = window.matchMedia('(prefers-color-scheme: dark)')
        window.removeEventListener('n3:theme-change', N3ThemeListener);
        if (typeof matchedMedia.removeEventListener === 'function') {
          matchedMedia.removeEventListener('change', systemColorSchemeListener);
        }
        else if (typeof matchedMedia.removeListener === 'function') {
          matchedMedia.removeListener(systemColorSchemeListener);
        }
      };
    }
  }, []);

  /// first time visitor  ---------------------------------------------------------------
  const [seenWhatsNewModal, setSeenWhatsNewModal] = useLocalStorageState(ENV_SEEN_WHATS_NEW_MODAL, {defaultValue: true});

  return (
    <UserSettingsContext.Provider
      value={{
        isDarkMode: appTheme === 'dark' || typeof appTheme === 'undefined' && systemDarkMode,
        setDarkMode: setDarkMode,
        sportEnabled: sportsEnabledSelected !== null ? sportsEnabledSelected : sportEnabledCookie === true,
        setSportEnabled: setSportEnabled,
        autoAddNewPosts: manualUpdatesSelected !== null ? manualUpdatesSelected : autoAddNewPostsCookie === true,
        setAutoAddNewPosts: setAutoAddNewPosts,
        lastArticleSeen: Math.floor(lastArticleSeen),
        setLastArticleSeen: setLastArticleSeen,
        showWhatsNew: areCookiesEnabled && seenWhatsNewModal !== true,
        setSeenWhatsNew: () => setSeenWhatsNewModal(true),
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useAutoAddNewPostsSetting = () => {
  const ctx = useUserSettingsContext();
  return ctx.autoAddNewPosts;
};

const withUserSettingsContext = (Component) => (props) => {
  const value = useUserSettingsContext();
  return <Component {...props} {...value} />;
};

export default withAppContext(UserSettingsContextProvider);
export { useUserSettingsContext, withUserSettingsContext };
