import React from 'react';
import { useHistory } from 'react-router';
import { withMessages } from '../ContextProviders/LanguageContextProvider';
import SpriteMap from './SpriteMap';

const ToolbarBackLink = ({ messages }) => {
  const history = useHistory();
  return (
    <button onClick={() => history.goBack()} className={'mnt-backLink'} title={messages.backToNews}>
      <SpriteMap sprite={'chevron-left'} />
      <span>{messages.backToNews}</span>
    </button>
  );
};

export default withMessages(ToolbarBackLink);
