import React from 'react';
import PropTypes from 'prop-types';
import { withMessages } from '../ContextProviders/LanguageContextProvider';
import SpriteMap from './SpriteMap';
import { withGTMMTrackerContext } from '../ContextProviders/GTMTrackerContextProvider';
import {useDispatch, useSelector} from "react-redux";
import { applyPendings, countOfPendingPostsSelector } from '../Redux/postsSlice';
import {withScrollingHelperContext} from "../ContextProviders/ScrollingHelperContext";
import useFeedConfiguration from "./Hooks/useFeedConfiguration";

const PendingPostsCTA = ({ scrollToFirstArticle, messages, trackFetchNewPosts }) => {
  const dispatch = useDispatch();
  const feedConfiguration = useFeedConfiguration();
  const countOfPendingPosts = useSelector(countOfPendingPostsSelector);

  const postsPendingMessage = messages.newPostsPendingFn(countOfPendingPosts);
  const onLoadPendingClick = () => {
    trackFetchNewPosts();
    dispatch(applyPendings());
    scrollToFirstArticle();
  };

  if (feedConfiguration.autoAddNewPosts || (countOfPendingPosts <= 0 || countOfPendingPosts > 30)) return <></>;

  return (
    <div className={'mnt-PendingPostsCTA'}>
      <button onClick={onLoadPendingClick} aria-label={postsPendingMessage}>
        <SpriteMap sprite={'expand_all'} />
        {postsPendingMessage}
      </button>
    </div>
  );
};

PendingPostsCTA.propTypes = {
  onClick: PropTypes.func.isRequired,
  countOfPendingPosts: PropTypes.number.isRequired,
};

export default withScrollingHelperContext(withGTMMTrackerContext(withMessages(PendingPostsCTA)));
