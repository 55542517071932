import React, { useEffect, useState } from 'react';
import SpriteMap from './SpriteMap';
import { withAppContext } from '../ContextProviders/AppContextProvider';

const convertToSrcSet = (imgSizes) => {
  return imgSizes
    .map((size) => {
      return `${size.url} ${size.width}w`;
    })
    .join(',');
};

const ArticleImage = ({ article, openGallery, appType }) => {
  const [imgHrefUrl, setImgHrefUrl] = useState(article.image ? article.image.sizes[article.image.sizes.length - 1].url : null);
  const [imgSrcSet] = useState(article.image ? convertToSrcSet(article.image.sizes) : '');
  const hasExternalTarget = article.image.target_link !== null;

  useEffect(() => {
    if (appType !== 'feed') {
      article.image.target_link === null ? setImgHrefUrl('#') : setImgHrefUrl(article.image.target_link);
    }
  }, []);

  const onImageClick = (e) => {
    if (appType !== 'feed' && article.image.target_link === null) {
      e.preventDefault();
      openGallery(null, true);
      return false;
    }
    return true;
  };

  return (
    <figure>
      <a href={imgHrefUrl} target="_blank" onClick={onImageClick}>
        <img
          width={article.image.sizes[article.image.sizes.length - 1].width}
          height={article.image.sizes[article.image.sizes.length - 1].height}
          src={article.image.sizes[article.image.sizes.length - 1].url}
          srcSet={`${imgSrcSet}`}
          sizes={'(min-width: 1200px) 720px, 100vw'}
          alt={article.image.alt}
          loading={"lazy"}
        />
         {/*@TODO optimize for above the foald to load eager */}
        {appType !== 'feed' && (
          <span className={'mnt-zoom'}>
            <SpriteMap sprite={hasExternalTarget ? 'external-link' : 'search'} />
          </span>
        )}
      </a>
      {article.image.excerpt && <figcaption>{article.image.excerpt}</figcaption>}
    </figure>
  );
};

export default withAppContext(ArticleImage);
