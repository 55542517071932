import FsLightbox from 'fslightbox-react';
import React, { useContext, useState } from 'react';

const GalleryContext = React.createContext();
const useGalleryContext = () => useContext(GalleryContext);

const GalleryContextProvider = ({ children }) => {
  const [images, setImages] = useState([]);
  const [galleryData, setGalleryData] = useState({
    images: [],
    key: 0,
    activeSlide: 1,
  });
  const [toggler, toggleGallery] = useState(false);

  const openGallery = (sources, activeSlideId) => {
    setGalleryData({
      images: sources.map(({ src, width, height, caption }) => (
        <figure>
          <img src={src} width={width} height={height} alt={caption} />
          {caption && <figcaption>{caption}</figcaption>}
        </figure>
      )),
      activeSlide: activeSlideId,
    });

    setTimeout(() => toggleGallery(!toggler), 5);
  };

  return (
    <GalleryContext.Provider
      value={{
        openGallery: openGallery,
      }}
    >
      <>
        {children}
        <FsLightbox key={galleryData.images.length} toggler={toggler} sources={galleryData.images} slide={galleryData.activeSlide} disableLocalStorage={true} />
      </>
    </GalleryContext.Provider>
  );
};

const withGallery = (Component) => (props) => {
  const gallery = useGalleryContext();

  return <Component {...props} openGallery={gallery.openGallery} />;
};

export default GalleryContextProvider;
export { useGalleryContext, withGallery };
