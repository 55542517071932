import React from 'react';
import ArticleDateTime from '../ArticleDateTime';
import AdminEditLink from '../AdminEditLink';
import SpriteMap from '../SpriteMap';
import ShareLinksMenu from '../ShareLinksMenu';
import { withAppContext } from '../../ContextProviders/AppContextProvider';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';

const FeedArticleToolbar = ({ messages, article, showShareMenu, toggleShareMenuFn, appType, isImportant }) => {
  const toggleShareOnClick = (event) => {
    if (navigator && navigator.share && window && window.innerWidth < 800) {
      const domParser = new DOMParser();

      let shareTitle = article.excerpt.match(/<a.*?>(.*?)<\/a>/);
      if (shareTitle === null) {
        shareTitle = article.excerpt.match(/<strong.*?>(.*?)<\/strong>/);
      }
      if (shareTitle !== null) {
        shareTitle = domParser.parseFromString(shareTitle[1], 'text/html').body.textContent;
      }
      let shareBody = domParser.parseFromString(article.excerpt, 'text/html').body.textContent;

      try {
        navigator.share({
          url: article.url,
          title: shareTitle,
          text: shareBody,
        });
        return;
      } catch (ex) {}
    }

    toggleShareMenuFn();
    event.preventDefault();
    return false;
  };

  return (
    <div className={'mnt-toolbar'}>
      <a href={ENV_DENNIKN_DETAIL_URL + article.id + '/'} target="_blank" rel="noreferrer" className={`${isImportant && APP_LANG === 'cz' ? 'mnt-Important-cz' : ''}`}>
        <ArticleDateTime dateString={article.published_at} />
      </a>
      <div>
        <AdminEditLink url={ENV_EDIT_POST_URL} id={article.id} />

        {appType !== 'feed' ? (
          <button className={'mnt-External'} onClick={toggleShareOnClick} title={'Social media'} aria-label={messages.areaShareArticle} aria-expanded={showShareMenu ? 'true' : 'false'}>
            {APP_LANG === 'sk' && showShareMenu ? <SpriteMap sprite={'clear-sk'} /> : <SpriteMap sprite={'share_vs'} />}
          </button>
        ) : (
          <a href={`#share:${article.id}`}>
            <button className={'mnt-External'} title={'Social media'} aria-label={messages.areaShareArticle}>
              <SpriteMap sprite={'share_vs'} />
            </button>
          </a>
        )}
      </div>
      {showShareMenu && <ShareLinksMenu toggleShareMenuFn={toggleShareMenuFn} articleObject={article} />}
    </div>
  );
};

export default withAppContext(withMessages(FeedArticleToolbar));
