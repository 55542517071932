import React from 'react';
import SpriteMap from './SpriteMap';
import SWLink from './SWLink';

const IconLink = ({ icon, to, isActive, children }) => {
  return (
    <SWLink className={'mnt-IconLink' + (isActive === true ? ' active' : '')} to={to}>
      <SpriteMap sprite={icon} />
      {children}
    </SWLink>
  );
};

export default IconLink;
