import React, { useState } from 'react';
import FeedLiveArticleToolbar from './FeedArticle/FeedLiveArticleToolbar';
import SWLink from './SWLink';
import { chooseBestSizeImageUrl } from '../utils/image_sizes';
import { useSelector } from 'react-redux';
import { canEditSelector } from '../Redux/setupSlice';

const LiveArticle = ({ article, showShareMenu, toggleShareMenuFn }) => {
  const canEdit = useSelector(canEditSelector);
  const [articleUrl] = useState(() => ENV_APP_LIVE_ROUTE.replace(':id', article.id).replace(':live', article.slug));
  const [thumbnailImage] = useState(() => {
    if (article.image) return chooseBestSizeImageUrl(article.image.sizes, 130);
    return undefined;
  });

  return (
    <>
      {!canEdit ? (
        <SWLink to={articleUrl} className={'mnt-FeedArticle mnt-articleLive js-hook-feed-article' + (article.isActive ? ' mnt-articleCurrentlyLive' : 'mnt-articleArchivedLive')}>
          <FeedLiveArticleToolbar article={article} toggleShareMenuFn={toggleShareMenuFn} showShareMenu={showShareMenu} />

          <div className={`mnt-liveLink-wrapper ${thumbnailImage ? '' : 'mnt-liveLink-noImg'}`}>
            <div>
              <p  className={'mnt-liveLink'} dangerouslySetInnerHTML={{ __html: article.name }}></p>
              {article.firstAuthor && <p className={'mnt-liveAuthor'}>{article.firstAuthor.name}</p>}
            </div>
            {thumbnailImage && <img className={'mnt-PostImage'} src={thumbnailImage.url} width={thumbnailImage.width} height={thumbnailImage.height} alt={article.image.excerpt | ''} />}
          </div>
        </SWLink>
      ) : (
        <div className={'mnt-FeedArticle mnt-articleLive js-hook-feed-article' + (article.isActive ? ' mnt-articleCurrentlyLive' : 'mnt-articleArchivedLive')}>
          <FeedLiveArticleToolbar article={article} toggleShareMenuFn={toggleShareMenuFn} showShareMenu={showShareMenu} />

          <SWLink to={articleUrl}>
            <div className={`mnt-liveLink-wrapper ${thumbnailImage ? '' : 'mnt-liveLink-noImg'}`}>
              <div>
                <p>
                  <span className={'mnt-liveLink'} dangerouslySetInnerHTML={{ __html: article.name }} />
                </p>
                {article.firstAuthor && <p className={'mnt-liveAuthor'}>{article.firstAuthor.name}</p>}
              </div>
              {thumbnailImage && <img className={'mnt-PostImage'} src={thumbnailImage.url} width={thumbnailImage.width} height={thumbnailImage.height} alt={article.image.excerpt | ''} />}
            </div>
          </SWLink>
        </div>
      )}
    </>
  );
};

export default LiveArticle;
