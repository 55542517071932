import React, { useEffect } from 'react';

const InjectSVGToDom = ({ children }) => {
  useEffect(() => {
    const existingDiv = document.getElementById('mnt-svg-spritemap');
    if (existingDiv !== null) return;
    // let url = 'https://mnt.risingn.sk/minuta/assets/assets/spritemap.svg';
    let url = WEBAPP_ROOT_PATH + '/' + WEBPACK_ASSETS_FOLDER + '/spritemap.svg';
    if (WEBPACK_ASSETS_FOLDER_OVERRIDE) {
      url = WEBPACK_ASSETS_FOLDER_OVERRIDE + '/spritemap.svg';
    }
    var ajax = new XMLHttpRequest();
    ajax.open('GET', url, true);
    ajax.send();
    ajax.onload = function (e) {
      var div = document.createElement('div');
      div.id = 'mnt-svg-spritemap';
      div.style.display = 'none';
      div.innerHTML = ajax.responseText;
      document.body.insertBefore(div, document.body.childNodes[0]);
    };
  }, []);

  return <>{children}</>;
};

export default InjectSVGToDom;
