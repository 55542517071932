import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const TrendingPostsSkeleton = ({ repeat }) => {
  return (
    <SkeletonTheme color="#222222" highlightColor="#444">
      {new Array(repeat).fill(0).map((_, i) => (
        <a key={i} className={'mnt-TrendingPost'}>
          <p>
            <Skeleton count={2} />
            <Skeleton width={'30%'} />
          </p>
          <span className={'mnt-PostImage'}>
            <Skeleton width={'120px'} height={'80px'} />
          </span>
        </a>
      ))}
    </SkeletonTheme>
  );
};

export default TrendingPostsSkeleton;
