import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import CurrentPosts from './CurrentPosts';
import TrendingPosts from './TrendingPosts';
import SpriteMap from '../../Components/SpriteMap';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';

const Aside = ({ messages }) => {
  const [height, setHeight] = useState('');
  const currRef = useRef();

  useEffect(() => {
    if (currRef?.current) setHeight(currRef.current.clientHeight);

    const handleResize = () => {
      if (currRef?.current) setHeight(currRef.current.clientHeight);
    };
    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, [currRef, currRef.current]);
  return (
    <div className={'mnt-Aside'}>
      <StyledDiv ref={currRef} className={'mnt-postBlock'} height={height}>
        <div className={'mnt-AsideHeader'}>
          <h3>
            <SpriteMap sprite={'hot-' + APP_LANG} />
            {messages.topNews}
          </h3>
          {APP_LANG === 'cz' && (
            <div className={'mnt-HeaderLogo'}>
              <SpriteMap sprite={'logo_N'} />
            </div>
          )}
        </div>
        <TrendingPosts />
      </StyledDiv>
      <StyledDiv className={'mnt-postBlock'} height={height}>
        <div className={'mnt-AsideHeader'}>
          <h3>
            <SpriteMap sprite={'star-' + APP_LANG} />
            {messages.newOnDennikN}
          </h3>
        </div>
        <CurrentPosts />
      </StyledDiv>
    </div>
  );
};

export default withMessages(Aside);

const StyledDiv = styled.div`
  ${(props) =>
    props.height &&
    css`
      &:after {
        top: ${`calc(${props.height}px - 20%)`} !important;
      }
    `}
`;
