import React from 'react';
import ToolbarLogo from '../../Components/ToolbarLogo';
import ToolbarBackLink from '../../Components/ToolbarBackLink';
import ToolbarActions from '../../Components/ToolbarActions';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector, countOfPendingPostsSelector, applyPendings, hasHistorySelector } from '../../Redux/postsSlice';
import { isLoadingSelector as isSearchLoadingSelector } from '../../Redux/searchSlice';
import { toggleMobileMenu } from '../../Redux/appSlice';
import SpriteMap from '../../Components/SpriteMap';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';
import SWLink from '../../Components/SWLink';
import { withAppContext } from '../../ContextProviders/AppContextProvider';

const Toolbar = ({ messages, appType }) => {
  const dispatch = useDispatch();
  /// logo is animated only while posts request is running
  const isLoading = useSelector(isLoadingSelector);
  const isSearchLoading = useSelector(isSearchLoadingSelector);
  const hasHistory = useSelector(hasHistorySelector);

  const onHamburgerClicked = (event) => {
    dispatch(toggleMobileMenu());
    event.preventDefault();
    return false;
  };

  return (
    <div className={'mnt-Toolbar'}>
      <div className={'mnt-ToolbarDesk'}>
        <div className={'mnt-LeftBar'}>
          <ToolbarLogo animateLogo={isLoading || isSearchLoading} />
        </div>
        <div className={'mnt-CenterBar mnt-darkTheme'}>{appType === 'app' && hasHistory && <ToolbarBackLink />}</div>
        <div className={'mnt-RightBar'}>
          <ToolbarActions />
          <a href={messages.linkToDennikN} title={messages.dennikN} className={`mnt-logoN mnt-logo-header ${APP_LANG === 'cz' ? 'mnt-logoN-cz' : ''}`}>
            {messages.dennikN}
            <SpriteMap sprite={'logo_N'} />
          </a>
        </div>
      </div>
      <div className={'mnt-ToolbarMob'}>
        <div className={'mnt-LeftBar'}>
          {appType === 'app' && hasHistory ? (
            <>
              {APP_LANG === 'sk' && <ToolbarLogo animateLogo={isLoading} />}
              <div className={'mnt-darkTheme'}><ToolbarBackLink /></div>
            </>
          ) : (
            <SWLink to={ENV_APP_HOMEPAGE_ROUTE} className={'mnt-logoMnt'} title={messages.logoText}>
              {APP_LANG === 'sk' && <ToolbarLogo animateLogo={isLoading} />}
              <span className={'mnt-logoTitle'} dangerouslySetInnerHTML={{ __html: messages.logoTextHtml }} />
            </SWLink>
          )}
        </div>
        {APP_LANG === 'cz' && (
          <div className={'mnt-CenterBar'}>
            <ToolbarLogo animateLogo={isLoading} />
          </div>
        )}
        <div className={'mnt-RightBar'}>
          <button className={'mnt-hamburgerMenu'} onClick={onHamburgerClicked}>
            <SpriteMap sprite={'hamburger'} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default withMessages(withAppContext(Toolbar));
