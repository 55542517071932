import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SpriteMap from './SpriteMap';
import SharingButtonsList, { ShareTo } from './SocialNetworks/SharingButtonsList';

const ShareLinksMenu = ({ toggleShareMenuFn, articleObject }) => {
  const popupRef = useRef();

  useEffect(() => {
    function onWindowClickEventListener(event) {
      /// everything outside of this menu (popupRef) will trigger toggleShareMenuFn
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        toggleShareMenuFn();
      }
    }

    /// we will create an event handler for "window" clicks
    /// it will be used to close open menues
    window.addEventListener('click', onWindowClickEventListener);

    /// unregister event listener
    return () => {
      window.removeEventListener('click', onWindowClickEventListener);
    };
  }, [popupRef]);

  return (
    <div className={'mnt-shareLinks'} ref={popupRef} role="dialog">
      {APP_LANG === 'cz' && (
        <button className={'mnt-claerShareLinks'} onClick={toggleShareMenuFn}>
          <SpriteMap sprite={'clear-' + APP_LANG} />
        </button>
      )}
      <nav className={'mnt-shares'}>
        <SharingButtonsList
          articleObject={articleObject}
          afterClick={toggleShareMenuFn}
          shareToItems={[
            /// Enabled Sharing buttons
            ShareTo.FACEBOOK,
            // ShareTo.MESSENGER,
            ShareTo.XTWITTER,
            ShareTo.WHATSAPP,
            ShareTo.EMAIL,
            ShareTo.COPYTOCLIPBOARD,
          ]}
        />
      </nav>
    </div>
  );
};

ShareLinksMenu.propTypes = {
  articleObject: PropTypes.object.isRequired,
  /// callback to be coled once close button is clicked
  toggleShareMenuFn: PropTypes.func.isRequired,
};

export default ShareLinksMenu;
