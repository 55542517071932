import React from 'react';
import { Provider } from 'react-redux';
import Routes from './Routes';
import PropTypes from 'prop-types';
import MainLayout from './Layout/MainLayout';
import "../Styles/style_sk.scss";
import LanguageContextProvider from '../ContextProviders/LanguageContextProvider';
import WorkboxCacheHandler from '../Components/Controllers/WorkboxCacheHandler';
import SWUpdateContextProvider from '../ContextProviders/SWUpdateContextProvider';
import NetworkStateContextProvider from '../ContextProviders/NetworkStateContextProvider';
import AppContextProvider from '../ContextProviders/AppContextProvider';
import UserSettingsContextProvider from '../ContextProviders/UserSettingsContextProvider';
import InjectSVGToDom from './Sections/InjectSVGToDom';

const AppShell = ({ RouterComponent, routerConfig, storeObject }) => {
  return (
    <InjectSVGToDom>
      <LanguageContextProvider>
        <Provider store={storeObject}>
          <RouterComponent {...routerConfig}>
            <AppContextProvider appType={'app'}>
              <UserSettingsContextProvider>
                <NetworkStateContextProvider>
                  <WorkboxCacheHandler>
                    <SWUpdateContextProvider>
                      <MainLayout>
                        <Routes appType={'app'} />
                      </MainLayout>
                    </SWUpdateContextProvider>
                  </WorkboxCacheHandler>
                </NetworkStateContextProvider>
              </UserSettingsContextProvider>
            </AppContextProvider>
          </RouterComponent>
        </Provider>
      </LanguageContextProvider>
    </InjectSVGToDom>
  );
};

AppShell.propTypes = {
  /// Router type to be used in application
  /// StaticRouter - for server rendering
  /// BrowserRouter - for client side rednering
  RouterComponent: PropTypes.elementType.isRequired,
  /// anything related to router properties
  /// it will be spreaded, so there is no structure
  routerConfig: PropTypes.object.isRequired,
  /// Redux store object
  storeObject: PropTypes.object.isRequired,
};

export default AppShell;
