import React from 'react';
import { Helmet } from 'react-helmet';
import { withMessages } from '../ContextProviders/LanguageContextProvider';
import { useLocation } from 'react-router-dom';

const MetaData = ({ messages, metaData }) => {
  const location = useLocation();
  const ogImageStatic = {
    sk: `${WEBAPP_URL}${WEBAPP_ROOT_PATH}/og-minuta-po-minute.png`,
    cz: 'https://static.novydenik.com/2022/11/Snimekobrazovky2022-11-24v85302.png',
  };

  return (
    <Helmet>
      <title>{metaData.name ? `${metaData.name} - ${messages.headTitle}` : messages.headTitle}</title>
      <link rel={'canonical'} href={WEBAPP_URL + location.pathname} />
      <meta property={'fb:app_id'} content={messages.fbAppId} />
      <meta property={'og:image'} content={metaData.image ? metaData.image.sizes[metaData.image.sizes.length - 1].url : ogImageStatic[APP_LANG]} />
      <meta property={'og:image:width'} content={metaData.image ? metaData.image.sizes[metaData.image.sizes.length - 1].width : '640'} />
      <meta property={'og:image:height'} content={metaData.image ? metaData.image.sizes[metaData.image.sizes.length - 1].height : '320'} />
      <meta property={'og:locale'} content={messages.longCode} />
      <meta property={'og:site_name'} content={messages.appName} />
      <meta property={'og:description'} content={`${messages.appName}, ${messages.appDescription}`} />
      <meta property={'og:title'} content={metaData.name ? `${metaData.name} - ${messages.headTitle}` : messages.headTitle} />
      <meta property={'og:type'} content={'article'} />
      <meta property={'og:url'} content={WEBAPP_URL + location.pathname} />
      <meta name={'twitter:description'} content={`${messages.appName}, ${messages.appDescription}`} />
      <meta name={'twitter:card'} content={'summary_large_image'} />
      <meta name={'twitter:title'} content={metaData.name ? `${metaData.name} - ${messages.headTitle}` : messages.headTitle} />
    </Helmet>
  );
};

export default withMessages(MetaData);
