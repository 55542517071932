module.exports = {
  /// urls  ----------------------------------------
  url: {
    important: 'dulezite',
    theme: 'tema',
    category: 'rubrika',
    live: 'live',
    liveStream: 'online-prenosy',
    search: 'hledej',
    detail: 'minuta',
  },
  /// settings  ----------------------------------------
  shortCode: 'cz',
  longCode: 'cz_CZ',

  appName: 'Minuta N',
  appShortName: 'Minuta N',
  appDescription: 'Nejrychlejší zprávy Deníku N',

  /// --------------------------------------------------
  newPostsPending: 'Nové minuty',
  newPostsPendingFn: (countOfNewPosts) => {
    if (countOfNewPosts === 1) return '1 nová zpráva';
    if (countOfNewPosts <= 4) return `${countOfNewPosts} nové zprávy`;
    return `${countOfNewPosts} nových zpráv`;
  },

  /// Menu section  ------------------------------------
  menuLive: 'Online přenosy',
  activeOnlineLiveNews: 'AKTUÁLNĚ',
  currentTags: 'AKTUÁLNÍ TÉMATA',
  settings: 'Nastavení',
  categories: 'Rubriky',
  subscribeToNewsletter: 'Denní přehledy z Minuty N do e-mailu ',
  showSport: 'Sport',
  showSportLong: 'Zobrazit sport',
  darkMode: 'Tmavý režim',
  search: 'Hledat',
  liveBadge: 'Živě',
  important: 'Nejdůležitější',

  /// Toolbar ------------------------------------------
  logoText: 'minutan.cz',
  logoTextHtml: 'MinutaN.<small>cz</small>',
  dennikN: 'DENÍK',
  linkToDennikN: 'https://denikn.cz/',
  backToNews: 'Zpět',
  buySubscription: 'Koupit předplatné',
  linkToSubscription: 'https://predplatne.denikn.cz/',
  login: 'Přihlásit se',
  myAccount: 'Můj účet',
  searchToolbar: 'Zadejte hledaný výraz',
  searchResults: 'Výsledky vyhledávání',
  mntSK: 'MinutaN.cz',

  /// Aside --------------------------------------------
  newOnDennikN: 'Nové články Deníku N',
  topNews: 'Aktuálně nejčtenější',

  /// Sharing ------------------------------------------
  facebook: 'Facebook',
  messenger: 'Messenger',
  twitter: 'X (Twitter)',
  whatsApp: 'WhatsApp',
  eMail: 'e-mail',
  copyToClipboard: 'Kopírovat odkaz',
  copiedToClipboard: 'Zkopírované',
  shareLiveArticle: 'Sdílet online přenos',

  /// Feed  --------------------------------------------
  readMoreFn: (countOfWords) => {
    return 'Přečíst celou zprávu';
  },
  /// Live archive -------------------------------------
  liveArchiveTitle: 'Online přenosy',
  liveUpdatedNow: 'Aktualizováno nyní',
  liveUpdatedAt: 'Aktualizováno v',
  liveHiddenAuthors: 'a další',

  /// message used in SSR
  readMorePlaceholder: 'Přečíst celou zprávu',
  readMoreOnMpm: 'Více zpráv na minutan.cz',
  readMoreLiveOnMpm: 'Otevřít celý přenos',

  /// Widget menu
  newPostsChoiceTitle: 'Aktualizace minuty',
  autoAddNewPosts: 'automaticky',
  autoAddNewPostsDescription: 'Nové zprávy se načítají automaticky',
  manuallyAddNewPosts: 'manuálně',
  manuallyAddNewPostsDescription: 'Nové zprávy se načítají po kliknutí',
  linkToMnt: 'https://www.denikn.cz/minuta?ref=top',
  menuMinuta: 'Menu - Minuta N',
  openMntInTab: 'Otevřít stránku Minuta N na nové kartě',

  /// Area strings -------------------------------------
  areaReadMore: 'Přečíst celou zprávu',
  areaCloseMenu: 'Zavřít menu',
  areaNewPostsPending: 'Nové zprávy',
  areaPopularPostsList: 'Seznam populárních/přiřazených témat',
  areaShareArticle: 'Sdílet článek',
  areaShareArticleOn: 'Sdílet článek na:',
  areaTagsLeft: 'Posunout vlevo',
  areaTagsRight: 'Posunout vpravo',
  areaSettingsManual: 'Aktualizace Minuty manuálně',
  areaSettingsAutomatic: 'Aktualizace Minuty automaticky',

  /// Global strings -----------------------------------
  appIsOffline: 'Bez internetového připojení',
  urlNotFound: 'Nesprávné url',
  networkError: 'Nepodařilo se načíst nové zprávy',
  noPostsFound: 'Je nám líto, ale nic tu není.',

  /// Page Meta ----------------------------------------
  headTitle: 'Minuta N – nejrychlejší zpravodajství Deníku N',
  fbAppId: '407336536797423',

  /// Whats new modal ----------------------------------
  whatsNewTitle: 'Nová Minuta N od Deníku N',
  whatsNewDescription: 'Připomínky nám prosím pište na <a href="mailto:editori@denikn.cz">editori@denikn.cz</a>',
  whatsNewSubsectionTitle: 'Novinky v Minutě N',
  whatsNewBetterNavigation: 'Lepší přístup k rubrikám a tématům přes nové menu',
  whatsNewFasterLoading: 'Rychlejší načítání a otevírání minut bez opuštění stránky',
  whatsNewDarkMode: 'Výběr mezi tmavým a světlým režimem',
  whatsNewSearch: 'Vyhledávání',
  whatsNewPreviewSubsectionTitle: 'vzhled',
  whatsNewLightModeOption: 'Světlý režim',
  whatsNewDarkModeOption: 'Tmavý režim',
  whatsNewDoneButton: 'hotovo',

  redirectOldURLByHash: [
    {
      type: 'tag',
      fastRecognize: (hash) => hash.indexOf('#/tema/') > -1,
      pullSlug: (hash) => {
        const parts = hash.split('/');
        return parts[parts.length - 1].toLowerCase();
      },
      redirectToURL: (id, slug) => ENV_APP_TAG_ROUTE.replace(':id', id).replace(':tag', slug),
    },
    {
      type: 'category',
      fastRecognize: (hash) => hash.indexOf('#/rubrika/') > -1,
      pullSlug: (hash) => {
        const parts = hash.split('/');
        return parts[parts.length - 1].toLowerCase();
      },
      redirectToURL: (id, slug) => ENV_APP_CATEGORY_ROUTE.replace(':id', id).replace(':category', slug),
    },
  ],
};
