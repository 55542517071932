import React, { useState } from 'react';
import ArticleDateTime from '../../Components/ArticleDateTime';
import ArticleImage from '../../Components/ArticleImage';
import ArticleEmbed from '../../Components/ArticleEmbed';
import ShareLinksMenu from '../../Components/ShareLinksMenu';
import SpriteMap from '../../Components/SpriteMap';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';
import { withAppContext } from '../../ContextProviders/AppContextProvider';

const Article = ({ article, appType }) => {
  const [showShareMenu, setShareMenuVisibility] = useState(false);

  const toggleShareOnClick = (event) => {
    setShareMenuVisibility((value) => !value);
    event.preventDefault();
    return false;
  };

  return (
    <div className={'mnt-FeedArticle js-hook-feed-article'}>
      <div className={'mnt-toolbar'}>
        <ArticleDateTime dateString={article.published_at} />
        {appType !== 'feed' ? (
          <button className={'mnt-External'} onClick={toggleShareOnClick} aria-label={messages.areaShareArticle} aria-expanded={showShareMenu ? 'true' : 'false'}>
            <SpriteMap sprite={'share_vs'} />
          </button>
        ) : (
          <a href={`#share:${article.id}`}>
            <button className={'mnt-External'} aria-label={messages.areaShareArticle}>
              <SpriteMap sprite={'share_vs'} />
            </button>
          </a>
        )}
        {showShareMenu && <ShareLinksMenu toggleShareMenuFn={() => setShareMenuVisibility((value) => !value)} articleObject={article} />}
      </div>

      <div className={'mnt-article'} dangerouslySetInnerHTML={{ __html: article.excerpt + article.content }} />

      {/******************** Image       ****************************/}

      {article.image && <ArticleImage article={article} />}

      {/******************** Video       ****************************/}

      {article.embed && article.embed.map((embed) => <ArticleEmbed url={embed.link} hostName={embed.type} id={embed.id} key={embed.id} />)}
    </div>
  );
};

Article.propTypes = {
  /// Post object from /mpm/posts
  article: PropTypes.object.isRequired,
};

export default withAppContext(withMessages(Article));
