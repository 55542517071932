import React, { Fragment, useCallback, useEffect } from 'react';
import useFeedConfiguration from '../../Components/Hooks/useFeedConfiguration';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  //
  countOfPendingPostsSelector,
  errorMessageSelector,
  hasErrorSelector,
  hasMorePostsSelector,
  isLoadingSelector,
  postsSelector,
  tickPosts,
  fetchPosts,
  lastTimestampSelector,
  applyPendings,
  metaSelector,
} from '../../Redux/livesSlice';
import { matchUrl } from '../Routes';
import MetaData from '../../Components/MetaData';
import Ticker from '../../Components/Controllers/Ticker';
import FeedArticleSkeleton from '../../Components/Skeletons/FeedArticleSkeleton';
import { withScrollingHelperContext } from '../../ContextProviders/ScrollingHelperContext';
import { withAppContext } from '../../ContextProviders/AppContextProvider';
import { withUserSettingsContext } from '../../ContextProviders/UserSettingsContextProvider';
import { withToast } from '../../ContextProviders/ToastContextProvider';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';
import FeedSticky from '../../Components/FeedSticky';
import { Waypoint } from 'react-waypoint';
import { shareMenuVisibleForArticleIdSelector, toggleShareMenuForArticle } from '../../Redux/appSlice';
import LiveArticle from '../../Components/LiveArticle';
import useScrollableAncestor from '../../Components/Hooks/useScrollableAncestor';

const LiveArchive = ({ messages, showToast, appType, totalInactiveTime, scrollToTop, sportEnabled }) => {
  const feedConfiguration = useFeedConfiguration();
  const scrollableAncestor = useScrollableAncestor(appType);
  let location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const countOfPendingPosts = useSelector(countOfPendingPostsSelector);
  const hasMorePosts = useSelector(hasMorePostsSelector);
  const hasError = useSelector(hasErrorSelector);
  const errorMessage = useSelector(errorMessageSelector);
  const metaData = useSelector(metaSelector);
  const posts = useSelector(postsSelector);
  const isLoading = useSelector(isLoadingSelector);
  const showShareMenuForArticleId = useSelector(shareMenuVisibleForArticleIdSelector);
  const lastTimestamp = useSelector(lastTimestampSelector);

  /// GTM and REMP tracking ----------------------------------------------------
  // useTrackPageEvents(stickyContent);

  /**
   * decide what to do with pending posts
   * decision is made with help of the flag: autoAddNewPosts
   */
  useEffect(() => {
    if (feedConfiguration.autoAddNewLives && countOfPendingPosts > 0) {
      dispatch(applyPendings());
    }
  }, [countOfPendingPosts, feedConfiguration]);

  /**
   * method to be used by <Ticker component
   */
  const onTick = useCallback(() => {
    const routeMatch = matchUrl(location.pathname, appType);
    dispatch(tickPosts({ routeMatch }));
  }, [location.pathname, appType, dispatch]);

  const applyPendingsCallback = () => {
    dispatch(applyPendings());
    scrollToTop();
  };

  const waypointOnEnter = () => {
    if (isLoading || (hasError && Date.now() / 1000 - lastTimestamp < 10)) return;
    if (posts.length === 0) return;
    const routeMatch = matchUrl(location.pathname, appType);
    //TODO: find another way of how the pageNumber is determined
    dispatch(fetchPosts({ routeMatch, pageNumber: Math.floor(posts.length / 50) }));
  };

  useEffect(() => {
    if (hasError) {
      if (errorMessage === 'no_taxonomy') {
        history.replace(ENV_APP_HOMEPAGE_ROUTE);
      } else {
        showToast(messages.networkError);
      }
    }
  }, [hasError, errorMessage]);

  useEffect(() => {
    if (totalInactiveTime > ENV_RELOAD_AFTER_IDLE_TIME) {
      if (appType === 'app' && window && window.location) {
        /// in case of app, refresh page
        window.location.reload();
        document.scrollToTop();
      } else {
        /// in case of widget, just appendPendings
        countOfPendingPosts > 0 && applyPendingsCallback();
      }
    } else if (totalInactiveTime > 1) {
      onTick();
    }
  }, [totalInactiveTime]);

  return (
    <div className={'mnt-Feed'}>
      {appType === 'app' && <MetaData metaData={metaData} />}

      <Ticker
        //
        lastTimestamp={lastTimestamp}
        timeoutAfterSeconds={ENV_POSTS_TICKER_SPEED}
        onTick={onTick}
        skipFirst={false}
        tickerSpeedSeconds={ENV_POSTS_TICKER_SPEED}
        fireOnChange={[location]}
      />

      {isLoading && posts.length === 0 && <FeedArticleSkeleton repeat={5} />}

      {isLoading && posts.length === 0 && <FeedArticleSkeleton repeat={5} />}

      {metaData && <FeedSticky stickyContent={metaData} />}

      <div className={'mnt-Posts'}>
        {posts.map((article, i) => (
          <Fragment key={article.id}>
            {article.isNotSport && (
              <div id={article.id} className={'mnt-Post'}>
                {feedConfiguration.livesLazyLoad && hasMorePosts && posts.length - 15 === i && <Waypoint onEnter={waypointOnEnter} viewportBottom={300} scrollableAncestor={scrollableAncestor} />}
                <LiveArticle
                  //
                  article={article}
                  showShareMenu={showShareMenuForArticleId === article.id}
                  toggleShareMenuFn={() => dispatch(toggleShareMenuForArticle({ articleId: article.id }))}
                />
              </div>
            )}
            {!article.isNotSport && sportEnabled && (
              <div id={article.id} className={'mnt-Post'}>
                {feedConfiguration.livesLazyLoad && hasMorePosts && posts.length - 15 === i && <Waypoint onEnter={waypointOnEnter} viewportBottom={300} scrollableAncestor={scrollableAncestor} />}
                <LiveArticle
                  //
                  article={article}
                  showShareMenu={showShareMenuForArticleId === article.id}
                  toggleShareMenuFn={() => dispatch(toggleShareMenuForArticle({ articleId: article.id }))}
                />
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default withScrollingHelperContext(withAppContext(withUserSettingsContext(withToast(withMessages(LiveArchive)))));
