import React, { useEffect, useState } from 'react';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';
import SWLink from '../SWLink';
import { generatePath, useHistory } from 'react-router-dom';
import { chooseBestSizeImageUrl } from '../../utils/image_sizes';
import AdminEditLink from '../AdminEditLink';
import { withAppContext } from '../../ContextProviders/AppContextProvider';
import { useSelector } from 'react-redux';
import { canEditSelector } from '../../Redux/setupSlice';
import LivesTime from '../LivesTime';

const FeedStickyLiveHeaderBlock = ({ content, messages, appType }) => {
  const canEdit = useSelector(canEditSelector);
  const url = generatePath(ENV_APP_LIVE_ROUTE, { id: content.id, live: content.slug });
  const history = useHistory();
  const [thumbnailImage, setThumbnailImage] = useState(() => {
    if (content.image) return chooseBestSizeImageUrl(content.image.sizes, 130);
    return undefined;
  });

  useEffect(() => {
    if (content.image) setThumbnailImage(chooseBestSizeImageUrl(content.image.sizes, 130));
    else setThumbnailImage(undefined);
  }, [content]);

  useEffect(() => {
    if (appType === 'widget') {
      const openLive = (id, slug) => {
        const path = generatePath(ENV_APP_LIVE_ROUTE, { id: id, live: slug });
        history.push(path);
      };
      window.openLive = openLive;
    }
  });

  return (
    <>
      {!canEdit ? (
        <SWLink to={url}>
          <article className={'mnt-articleWithExcerpt mnt-articleLive'}>
            <div className={'mnt-liveShare-wrapper'}>
              <div className={'mnt-live-wrapper mnt-live-header'}>
                <span className={'mnt-live'}>{messages.liveBadge}</span>
                <time style={{ marginLeft: '10px' }} className={'mnt-time'}>
                  {new Date().getTime() - new Date(content.last_published_at).getTime() < 60000 ? (
                    messages.liveUpdatedNow
                  ) : (
                    <>
                      {messages.liveUpdatedAt} <LivesTime dateString={content.last_published_at} />
                    </>
                  )}
                </time>
              </div>
            </div>

            <div className={`mnt-liveLink-wrapper ${thumbnailImage ? '' : 'mnt-liveLink-noImg'}`}>
              <div>
                {content.name && (
                  <p>
                    <span className={'mnt-liveLink'}>{content.name}</span>
                  </p>
                )}
                {content.authors && content.authors.length > 0 && (
                  <p className={'mnt-liveAuthor'}>
                    {content.authors[0].name} {content.authors.length > 1 ? messages.liveHiddenAuthors : ''}
                  </p>
                )}
              </div>
              {thumbnailImage && <img className={'mnt-PostImage'} src={thumbnailImage.url} width={thumbnailImage.width} height={thumbnailImage.height} alt={content.image.excerpt | ''} />}
            </div>

            {/*{content.excerpt && <div dangerouslySetInnerHTML={{ __html: content.excerpt }} />}*/}
          </article>
        </SWLink>
      ) : (
        <article className={'mnt-articleWithExcerpt mnt-articleLive'}>
          <div className={'mnt-liveShare-wrapper'}>
            <div className={'mnt-live-wrapper'}>
              <span className={'mnt-live'}>{messages.liveBadge}</span>
              <span style={{ marginLeft: '10px' }} className={'mnt-time'}>
                {new Date().getTime() - new Date(content.last_published_at).getTime() < 60000 ? (
                  messages.liveUpdatedNow
                ) : (
                  <>
                    {messages.liveUpdatedAt} <LivesTime dateString={content.last_published_at} />
                  </>
                )}
              </span>
              <AdminEditLink url={ENV_EDIT_LIVE_URL} id={content.id} />
            </div>
          </div>
          <SWLink to={url}>
            <div className={`mnt-liveLink-wrapper ${thumbnailImage ? '' : 'mnt-liveLink-noImg'}`}>
              <div>
                {content.name && (
                  <p>
                    <span className={'mnt-liveLink'}>{content.name}</span>
                  </p>
                )}
                {content.authors && content.authors.length > 0 && <p className={'mnt-liveAuthor'}>{content.authors[0].name}</p>}
              </div>
              {thumbnailImage && <img className={'mnt-PostImage'} src={thumbnailImage.url} width={thumbnailImage.width} height={thumbnailImage.height} alt={content.image.excerpt | ''} />}
            </div>
          </SWLink>

          {/*{content.excerpt && <div dangerouslySetInnerHTML={{ __html: content.excerpt }} />}*/}
        </article>
      )}
    </>
  );
};

export default withAppContext(withMessages(FeedStickyLiveHeaderBlock));
