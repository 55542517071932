import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';
import SpriteMap from '../SpriteMap';
import ShareToFacebook from './ShareToFacebook';
import ShareToTwitter from './ShareToTwitter';
import ShareToWhatsApp from './ShareToWhatsApp';
import CopyToClipboard from './CopyToClipboard';
import ShareViaEmail from './ShareViaEmail';
import ShareLiveViaEmail from './ShareLiveViaEmail';

/// Enum of available sharing buttons
const ShareTo = {
  FACEBOOK: 'FACEBOOK',
  MESSENGER: 'MESSENGER',
  TWITTER: 'TWITTER',
  XTWITTER: 'XTWITTER',
  WHATSAPP: 'WHATSAPP',
  EMAIL: 'EMAIL',
  COPYTOCLIPBOARD: 'COPYTOCLIPBOARD',
};

const SharingButtonsList = ({ shareToItems, articleObject, afterClick, messages }) => {
  const [linkCopied, setLinkCopied] = useState(false);

  return (
    <>
      {shareToItems.map((networkKey, i) => {
        switch (networkKey) {
          case ShareTo.FACEBOOK:
            return (
              <ShareToFacebook url={articleObject.url} afterClick={afterClick} key={i}>
                <SpriteMap classname={'mnt-facebook'} sprite={'facebook-' + APP_LANG} />
                {messages.facebook}
              </ShareToFacebook>
            );

          case ShareTo.MESSENGER:
            return (
              <button title={'messenger'} key={i}>
                <SpriteMap classname={'mnt-messenger'} sprite={'messenger-' + APP_LANG} />
                {messages.messenger}
              </button>
            );

          case ShareTo.TWITTER:
            return (
              <ShareToTwitter url={articleObject.url} afterClick={afterClick} key={i}>
                <SpriteMap classname={'mnt-twitter'} sprite={'twitter-' + APP_LANG} />
                {messages.twitter}
              </ShareToTwitter>
            );

          case ShareTo.XTWITTER:
            return (
              <ShareToTwitter url={articleObject.url} afterClick={afterClick} key={i}>
                <SpriteMap classname={'mnt-twitter'} sprite={'x-twitter-' + APP_LANG} />
                {messages.twitter}
              </ShareToTwitter>
            );

          case ShareTo.WHATSAPP:
            return (
              <ShareToWhatsApp url={articleObject.url} afterClick={afterClick} key={i}>
                <SpriteMap classname={'mnt-whatsapp'} sprite={'whatsapp-' + APP_LANG} />
                {messages.whatsApp}
              </ShareToWhatsApp>
            );

          case ShareTo.EMAIL:
            if (articleObject.type === 'live') {
              return (
                <ShareLiveViaEmail url={articleObject.url} name={articleObject.name} content={articleObject.excerpt} afterClick={afterClick} key={i}>
                  <SpriteMap classname={'mnt-gmail'} sprite={'gmail-' + APP_LANG} />
                  {messages.eMail}
                </ShareLiveViaEmail>
              );
            }
            return (
              <ShareViaEmail url={articleObject.url} content={articleObject.excerpt} afterClick={afterClick} key={i}>
                <SpriteMap classname={'mnt-gmail'} sprite={'gmail-' + APP_LANG} />
                {messages.eMail}
              </ShareViaEmail>
            );

          case ShareTo.COPYTOCLIPBOARD:
            return (
              <CopyToClipboard url={articleObject.url} onLinkCopied={() => setLinkCopied(true)} key={i}>
                <SpriteMap sprite={'link-' + APP_LANG} />
                {linkCopied ? messages.copiedToClipboard : messages.copyToClipboard}
              </CopyToClipboard>
            );
        }
      })}
    </>
  );
};

SharingButtonsList.propTypes = {
  articleObject: PropTypes.object.isRequired,
  /// Array<ShareTo> - list of sharing buttons to be rendered
  shareToItems: PropTypes.array.isRequired,
};

export default withMessages(SharingButtonsList);
export { ShareTo };
