import React, { memo } from 'react';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';

const ShareToTwitter = ({ messages, url, afterClick, children }) => {
  const twitterUrl = 'https://www.twitter.com/share?url=' + url + '?ref=pop';

  const onClick = () => {
    window.open(twitterUrl, 'X (Twitter)', `width=480, height=480, top=${window.innerHeight / 2 - 240}, left=${window.innerWidth / 2 - 240}`);
    afterClick && afterClick();
  };

  return (
    <button href={twitterUrl} onClick={onClick} aria-label={messages.areaShareArticleOn + ' Twitter'}>
      {children}
    </button>
  );
};

export default memo(withMessages(ShareToTwitter));
