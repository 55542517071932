import React, { useEffect, useState } from 'react';
import { withMessages } from '../../ContextProviders/LanguageContextProvider';
import { getHighestResolutionImageObject } from '../../utils/image_sizes';
import SpriteMap from '../SpriteMap';
import ShareLinksMenu from '../ShareLinksMenu';
import { withGallery } from '../../ContextProviders/GalleryContextProvider';
import { withAppContext } from '../../ContextProviders/AppContextProvider';

const FeedStickyLiveHeader = ({ content, messages, openGallery, appType }) => {
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [image, setImage] = useState(() => {
    if (content.image) return getHighestResolutionImageObject(content.image.sizes);
    return undefined;
  });

  useEffect(() => {
    if (content.image) setImage(getHighestResolutionImageObject(content.image.sizes));
    else setImage(undefined);
  }, [content]);

  const onImageClick = (e) => {
    if (appType !== 'feed') {
      openGallery(
        [
          {
            src: image.url,
            width: image.width,
            height: image.height,
            caption: content.image.excerpt,
          },
        ],
        1
      );
      e.preventDefault();
      return false;
    }
  };

  return (
    <div className={'mnt-FeedStickyLiveHeader'}>
      {content.settings.indexOf('ended') === -1 && (
        <div className={'mnt-tags'} role="region" aria-label={messages.areaPopularPostsList}>
          <span className={'mnt-live'}>{messages.liveBadge}</span>
        </div>
      )}

      {content.name && <h1 className={'mnt-LiveHeaderTitle'} dangerouslySetInnerHTML={{ __html: content.name }} />}

      <div className={'mnt-LiveHeaderAuthorShare'}>
        <p className={'mnt-liveAuthor'}>{content.authors && content.authors.length > 0 ? content.authors.map((author) => author.name).join(', ') : ''}</p>
        <div className={'mnt-share-wrapper'}>
          {appType !== 'feed' && (
            <button className={'mnt-External'} onClick={() => setShowShareMenu(true)} title={'Social media'} aria-label={messages.areaShareArticle} aria-expanded={showShareMenu ? 'true' : 'false'}>
              <SpriteMap sprite={'share_vs'} /> {messages.shareLiveArticle}
            </button>
          )}
          {appType !== 'feed' && showShareMenu && <ShareLinksMenu toggleShareMenuFn={() => setShowShareMenu((oldState) => !oldState)} articleObject={content} />}
        </div>
      </div>
      {image && (
        <figure>
          <a href={appType !== 'feed' ? '#' : image.url} target="blank" className={'mnt-LiveHeaderImageWrapper'} onClick={onImageClick}>
            <img className={'mnt-LiveHeaderImage'} src={image.url} width={image.width} height={image.height} alt={content.image.excerpt} />
            {appType !== 'feed' && (
              <span className={'mnt-zoom'}>
                <SpriteMap sprite={'search'} />
              </span>
            )}
          </a>
          {content.image.excerpt && <figcaption className={'mnt-LiveHeaderImageCaption'}>{content.image.excerpt}</figcaption>}
        </figure>
      )}
    </div>
  );
};

export default withAppContext(withGallery(withMessages(FeedStickyLiveHeader)));
